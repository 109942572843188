import {
    Breadcrumb,
    BreadcrumbButton,
    BreadcrumbDivider,
    BreadcrumbItem,
    Button,
    Checkbox,
    Dialog,
    Tooltip,
} from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { ProviderState, Providers } from "@microsoft/mgt-element";
import { Channel, DriveItem } from "@microsoft/microsoft-graph-types";
import { app } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import ReactModal from "react-bootstrap/Modal";
import urlJoin from "url-join";
import { ChannelListResponse, DriveItemsResponse } from "../models/ms-graph";
import {
    getGroupIdFromContext,
    mimeTypeToDynizerType,
    pipeline,
    status,
} from "../models/pipeline";
import { center } from "./css";
import FileCustom from "./FileCustom";
import FileListModalAnalyze from "./FileListModalAnalyze";
import FileListModalRag from "./FileListModalRag";
import { channelView } from "./Header";
import Loading from "./Loading";
import Rag from "./Rag";
import VirtualScroller from "./VirtualScroller";

type Props = {
    reload: boolean;
    fileClick: (file: DriveItem) => void;
    startAnalysis: (files: DriveItem[]) => void;
    toRootFolder: () => void;
    fetchAndSetPipelines: (
        items: DriveItem[],
        append: boolean
    ) => Promise<void>;
    fetchPipelines: (items: DriveItem[]) => Promise<pipeline[]>;
    setError: (err: string) => void;
    currentFolder: DriveItem[];
    pipelines: pipeline[];
    selectedFile: DriveItem | undefined;
    context: app.Context | undefined;
    tenant: string;
    view: string;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    filterActive: boolean;
};

export default function FileList({
    reload,
    fileClick,
    startAnalysis,
    toRootFolder,
    fetchAndSetPipelines,
    fetchPipelines,
    setError,
    currentFolder,
    pipelines,
    selectedFile,
    context,
    tenant,
    view,
    loading,
    setLoading,
    filterActive,
}: Props) {
    const [nextPageUrl, setNextPageUrl] = useState<string | undefined>("");
    const [selectedItems, setSelectedItems] = useState<DriveItem[]>([]);
    const [checkAllItems, setCheckAllItems] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [showRagChat, setShowRagChat] = useState(false);
    const [showAnalyzeFileList, setShowAnalyzeFileList] = useState(false);
    const [showRagFileList, setShowRagFileList] = useState(false);
    const [ragFilesID, setRagFilesID] = useState<string[]>([]);
    const [channelFolders, setChannelFolders] = useState<DriveItem[]>([]);
    const [items, setItems] = useState<DriveItem[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<DriveItem[]>([]);

    useEffect(() => {
        if (!context) return;

        loadItems(false);
        setCheckAllItems(false);
    }, [currentFolder]);

    useEffect(() => {
        if (!context) return;

        loadItems(true);
        setCheckAllItems(false);
    }, [context, reload]);

    useEffect(() => {
        setSelectedItems(
            checkAllItems ? items.filter((item) => hasCheckBox(item)) : []
        );
    }, [checkAllItems]);

    useEffect(() => {
        setBreadcrumbs(currentFolder);
    }, [items]);

    const handleCheckChange = async (item: DriveItem, checked: boolean) => {
        setSelectedItems(
            checked
                ? [...selectedItems, item]
                : selectedItems.filter(
                      (selectedItem) => item.id !== selectedItem.id
                  )
        );
    };

    const handleCheckAllChange = () => {
        setCheckAllItems(!checkAllItems);
    };

    const handleStartAnalysis = (files: DriveItem[]) => {
        startAnalysis(files);
        setShowAnalyzeFileList(false);
    };

    const handleStartRag = (files: DriveItem[]) => {
        setRagFilesID(files.map((file) => file.id || ""));
        setShowRagFileList(false);
        setShowRagChat(true);
    };

    let noFilesToShow =
        items.length === 0 ||
        (filterActive &&
            !items.find(
                (item: DriveItem) => hasValidMimeType(item) || !item.file
            ));

    let noRelevantFiles =
        items.length === 0 ||
        !items.find((item: DriveItem) => hasValidMimeType(item) || !item.file);

    let groupId = getGroupIdFromContext(context);

    let showChannelList =
        view === channelView && groupId && !currentFolder.length;

    const handleError = (err: any) => {
        if (err.statusCode === 401) {
            Providers.globalProvider.setState(ProviderState.SignedOut);
        }
        setError(err.message);
    };

    const loadItems = async (reloadChannels: boolean) => {
        setLoading(true);

        try {
            if (showChannelList) {
                if (!reloadChannels && channelFolders.length > 0) {
                    setItems(channelFolders);
                    return;
                }

                const itemsResponse = await fetchChannels(groupId || "");
                const channelList = itemsResponse.value;

                setSelectedItems([]);
                setNextPageUrl(itemsResponse["@odata.nextLink"]);

                const channelFolderList = await fetchChannelFolders(
                    channelList,
                    groupId || ""
                );
                setItems(channelFolderList);
                setChannelFolders(channelFolderList);
            } else {
                const itemsResponse: DriveItemsResponse = await fetchDriveItems(
                    groupId,
                    view,
                    currentFolder,
                    channelFolders
                );

                await fetchAndSetPipelines(itemsResponse.value, false);
                setNextPageUrl(itemsResponse["@odata.nextLink"]);
                setItems(itemsResponse.value);
                setSelectedItems([]);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
            setInitialLoading(false);
        }
    };

    const loadNextPage = async () => {
        if (!nextPageUrl) return;

        setLoadingMore(true);
        setLoading(true);

        try {
            if (showChannelList) {
                const itemsResponse: ChannelListResponse = await fetchNextPage(
                    nextPageUrl
                );
                const channelsNext = itemsResponse.value;

                const nextChannelFolders = await fetchChannelFolders(
                    channelsNext,
                    groupId || ""
                );

                setItems((prevFolders) => [
                    ...prevFolders,
                    ...nextChannelFolders,
                ]);
                setChannelFolders((prevFolders) => [
                    ...prevFolders,
                    ...nextChannelFolders,
                ]);

                setNextPageUrl(itemsResponse["@odata.nextLink"]);
            } else {
                const itemsResponse: DriveItemsResponse = await fetchNextPage(
                    nextPageUrl
                );

                await fetchAndSetPipelines(itemsResponse.value, true);

                setItems((prevItems) => [...prevItems, ...itemsResponse.value]);

                setNextPageUrl(itemsResponse["@odata.nextLink"]);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoadingMore(false);
            setLoading(false);
        }
    };

    const jsxButtons = (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
            }}
        >
            <div style={{ marginLeft: "3px" }}>
                <Checkbox
                    checked={checkAllItems}
                    onChange={handleCheckAllChange}
                    disabled={noRelevantFiles || loading}
                />
            </div>

            <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <Button
                        appearance="primary"
                        disabled={
                            !selectedItems.length &&
                            (!selectedFile ||
                                !canBeAnalyzed(selectedFile, pipelines))
                        }
                        onClick={() => {
                            setShowAnalyzeFileList(true);
                        }}
                    >
                        Analyze{" "}
                    </Button>

                    <Button
                        appearance="primary"
                        disabled={
                            !selectedItems.length &&
                            (!selectedFile ||
                                !isAnalyzed(selectedFile, pipelines))
                        }
                        onClick={() => {
                            setShowRagFileList(true);
                        }}
                    >
                        MAG Chat
                    </Button>
                </div>
                <div>
                    <Tooltip content="Refresh" relationship="label">
                        <Button
                            appearance="secondary"
                            size="small"
                            onClick={() => {
                                if (loading) return;
                                try {
                                    loadItems(true);
                                } catch (err: any) {
                                    setError(err.message);
                                }
                            }}
                            icon={<ArrowClockwiseFilled />}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );

    const jsxNoFiles = (
        <div style={center}>
            {items.length === 0 ? (
                <div>Folder is empty</div>
            ) : (
                <div>No relevant files</div>
            )}
        </div>
    );

    const jsxFileList = items
        .filter((item) => !filterActive || !item.file || hasValidMimeType(item))
        .map((item) => (
            <FileCustom
                file={item}
                checked={!!selectedItems.find((el) => el.id === item.id)}
                checkChange={handleCheckChange}
                onClick={fileClick}
                key={item.id}
                pipeline={pipelines.find(
                    (pipeline) => pipeline.teamsId === item.id
                )}
                selected={selectedFile?.id === item.id}
            ></FileCustom>
        ));

    const breadcrumb = () => {
        let crumbs = [
            <>
                <BreadcrumbItem key={"root"}>
                    <BreadcrumbButton
                        onClick={() => {
                            if (!loading && breadcrumbs.length) toRootFolder();
                        }}
                        disabled={loading || !breadcrumbs.length}
                    >
                        {!groupId
                            ? "My Files"
                            : view === channelView
                            ? "Channels"
                            : context?.team?.displayName}
                    </BreadcrumbButton>
                </BreadcrumbItem>
                {breadcrumbs.length !== 0 && <BreadcrumbDivider key={"div"} />}
            </>,
            ...breadcrumbs.map((item, index) => (
                <>
                    <BreadcrumbItem key={item.id}>
                        <BreadcrumbButton
                            onClick={() => {
                                if (index !== breadcrumbs.length - 1)
                                    fileClick(item);
                            }}
                            disabled={loading}
                        >
                            {item.name}
                        </BreadcrumbButton>
                    </BreadcrumbItem>{" "}
                    {index != breadcrumbs.length - 1 && (
                        <BreadcrumbDivider key={"div" + item.id} />
                    )}
                </>
            )),
        ];

        return (
            <div style={{ overflow: "auto" }}>
                <Breadcrumb
                    aria-label="Folder navigation breadcrumbs"
                    size="medium"
                >
                    {crumbs}
                </Breadcrumb>
            </div>
        );
    };

    return (
        <>
            <Dialog
                open={showAnalyzeFileList}
                onOpenChange={(event, data) =>
                    setShowAnalyzeFileList(data.open)
                }
            >
                <FileListModalAnalyze
                    fetchPipelines={fetchPipelines}
                    selectedItems={
                        !selectedItems.length && selectedFile
                            ? [selectedFile]
                            : selectedItems
                    }
                    startAnalysis={handleStartAnalysis}
                    setError={setError}
                ></FileListModalAnalyze>
            </Dialog>

            <Dialog
                open={showRagFileList}
                onOpenChange={(event, data) => setShowRagFileList(data.open)}
            >
                <FileListModalRag
                    fetchPipelines={fetchPipelines}
                    selectedItems={
                        !selectedItems.length && selectedFile
                            ? [selectedFile]
                            : selectedItems
                    }
                    startAnalysis={handleStartRag}
                    setError={setError}
                ></FileListModalRag>
            </Dialog>

            <ReactModal
                show={showRagChat}
                onHide={() => setShowRagChat(false)}
                dialogClassName="tiles-modal"
            >
                <ReactModal.Header closeButton>
                    <ReactModal.Title>MAG Chat</ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                    <Rag
                        tenant={tenant}
                        fileIDs={ragFilesID}
                        context={context}
                    ></Rag>
                </ReactModal.Body>
            </ReactModal>

            <div style={{ marginBottom: "5px" }}>{jsxButtons}</div>

            {!!breadcrumbs.length && <div>{breadcrumb()}</div>}

            {initialLoading ? (
                <Loading />
            ) : noFilesToShow && !nextPageUrl ? (
                jsxNoFiles
            ) : (
                <VirtualScroller
                    loadingMore={loadingMore}
                    jsxItems={jsxFileList}
                    loadNextPage={loadNextPage}
                    hasMore={!!nextPageUrl}
                    nbLoadedFiled={items.length}
                ></VirtualScroller>
            )}
        </>
    );
}

export const canBeAnalyzed = (item: DriveItem, pipelines: pipeline[]) => {
    return (
        hasValidMimeType(item) &&
        !pipelines.find((pipeline) => pipeline.teamsId === item.id)
    );
};

export const isAnalyzed = (item: DriveItem, pipelines: pipeline[]) => {
    return (
        hasValidMimeType(item) &&
        pipelines.find(
            (pipeline) =>
                pipeline.teamsId === item.id &&
                pipeline.status === status.SUCCESS
        )
    );
};

export const hasCheckBox = (item: DriveItem) => {
    return item.folder || hasValidMimeType(item);
};

export const hasValidMimeType = (file: DriveItem) =>
    !!file.file?.mimeType && !!mimeTypeToDynizerType[file.file?.mimeType];

const fetchChannels = async (groupId: string) => {
    let response: Promise<ChannelListResponse> =
        Providers.globalProvider.graph.client
            .api(`/teams/${groupId}/allChannels?$select=id`)
            .get();

    return response;
};

const fetchChannelFolders = async (
    channelsNext: Channel[],
    groupId: string
) => {
    return Promise.all(
        channelsNext.map(async (channel) => {
            const filesFolder: DriveItem =
                await Providers.globalProvider.graph.client
                    .api(`/teams/${groupId}/channels/${channel.id}/filesFolder`)
                    .get();
            return filesFolder;
        })
    );
};

const fetchDriveItems = (
    groupId: string | undefined,
    view: string,
    currentFolder: DriveItem[],
    channelFolders: DriveItem[]
) => {
    let url = "";

    if (groupId) {
        if (view === channelView) {
            const itemID = currentFolder[currentFolder.length - 1].id;
            const channelFolder = channelFolders.find(
                (channelFolder) => channelFolder.id === currentFolder[0].id
            );
            const driveID = channelFolder?.parentReference?.driveId;
            url = `drives/${driveID}/items/${itemID}/children`;
        } else {
            url = currentFolder.length
                ? urlJoin(
                      `groups/${groupId}/drive/root:/`,
                      ...currentFolder.map((folder) => folder.name || "")
                  ) + ":/children"
                : `groups/${groupId}/drive/root/children`;
        }
    } else {
        url = currentFolder.length
            ? urlJoin(
                  `me/drive/root:/`,
                  ...currentFolder.map((folder) => folder.name || "")
              ) + ":/children"
            : `me/drive/root/children`;
    }

    return Providers.globalProvider.graph.client.api(url).get();
};

const fetchNextPage = async (nextPageUrl: string) => {
    return Providers.globalProvider.graph.client.api(nextPageUrl).get();
};
