import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
    MessageBar,
    MessageBarBody,
} from "@fluentui/react-components";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { pipeline } from "../models/pipeline";
import FileCustom from "./FileCustom";
import { canBeAnalyzed, isAnalyzed } from "./FileList";
import {
    DriveItemWithStatus,
    getFilesAndChildren,
} from "./FileListModalAnalyze";
import Loading from "./Loading";
import VirtualScroller from "./VirtualScroller";

type Props = {
    startAnalysis: (files: DriveItem[]) => void;
    selectedItems: DriveItem[];
    setError: (err: string) => void;
    fetchPipelines: (itemIds: DriveItem[]) => Promise<pipeline[]>;
};

export default function FileListModalRag({
    startAnalysis,
    selectedItems,
    setError,
    fetchPipelines,
}: Props) {
    const [files, setFiles] = useState<DriveItemWithStatus[]>([]);
    const [loading, setLoading] = useState(true);
    const [pipelines, setPipelines] = useState<pipeline[]>([]);
    const [nbToAnalyze, setNbToAnalyze] = useState<number>(0);

    useEffect(() => {
        fetchFiles();
    }, []);

    let nbFilesSelected = files.filter((file) => file.checked).length;

    const fetchFiles = async () => {
        setLoading(true);

        let { files, error } = await getFilesAndChildren(selectedItems);

        if (error) {
            setError(error.message);
            return;
        }

        let gotPipelines = await fetchPipelines(files);

        setPipelines(gotPipelines);

        let analyzedFiles: DriveItemWithStatus[] = files
            .filter((item) => isAnalyzed(item, gotPipelines))
            .map((item) => ({
                checked: true,
                driveItem: item,
            }));

        setNbToAnalyze(
            files.filter((item) => canBeAnalyzed(item, gotPipelines)).length
        );

        setFiles(analyzedFiles);

        setLoading(false);
    };

    const handleCheckChange = async (item: DriveItem, checked: boolean) => {
        setFiles(
            files.map((itemIn) => ({
                ...itemIn,
                checked:
                    itemIn.driveItem.id === item.id
                        ? !itemIn.checked
                        : itemIn.checked,
            }))
        );
    };

    const handleStart = () => {
        startAnalysis(
            files.filter((file) => file.checked).map((el) => el.driveItem)
        );
    };

    const jsxFileList = (items: DriveItemWithStatus[]) =>
        items.map((item) => (
            <FileCustom
                file={item.driveItem}
                checked={item.checked}
                checkChange={handleCheckChange}
                onClick={() => {}}
                key={item.driveItem.id}
                pipeline={pipelines.find(
                    (pipeline) => pipeline.teamsId === item.driveItem.id
                )}
                selected={false}
            ></FileCustom>
        ));

    const jxtNotAnalyzedWarning =
        nbToAnalyze !== 0 ? (
            <div style={{ marginBottom: "15px" }}>
                <MessageBar>
                    <MessageBarBody>
                        {nbToAnalyze}{" "}
                        {nbToAnalyze === 1
                            ? " selected file is"
                            : " selected files are"}{" "}
                        not analyzed yet. Do this first to include{" "}
                        {nbToAnalyze === 1 ? "it" : "them"} in MAG as well.
                    </MessageBarBody>
                </MessageBar>
            </div>
        ) : (
            <></>
        );

    return (
        <DialogSurface>
            <DialogBody>
                <DialogContent>
                    {loading ? (
                        <div style={{ height: "60px" }}>
                            <Loading />
                        </div>
                    ) : files.length ? (
                        <>
                            <div className="container">
                                {jxtNotAnalyzedWarning}

                                <VirtualScroller
                                    hasMore={false}
                                    jsxItems={jsxFileList(files)}
                                    loadNextPage={() => {}}
                                    loadingMore={false}
                                    nbLoadedFiled={files.length}
                                ></VirtualScroller>
                            </div>
                        </>
                    ) : (
                        <div>No analyzed files selected</div>
                    )}
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button
                        appearance="primary"
                        disabled={!nbFilesSelected}
                        onClick={handleStart}
                    >
                        Open Chat{" "}
                        {nbFilesSelected ? <>({nbFilesSelected})</> : <></>}
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    );
}
